module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K56682","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TopCompanions","short_name":"TopCompanions","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/favicon.png","icons":[{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"maskable"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b8216afded51ba1f78bf1b3675a29a40"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://api.topcompanions.com/graphql"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
